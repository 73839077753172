var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cost"},[_vm._l((_vm.projectStageBelongList),function(item,index){return [_c('el-card',{key:index},[_c('el-table',{attrs:{"data":_vm.stageData[`${item.deptClassifyId}`],"row-key":"uuId","border":"","default-expand-all":"","row-class-name":_vm.tableRowClassName,"tree-props":{ children: 'children', hasChildren: 'hasChildren' }},on:{"row-click":_vm.rowClick}},[_c('el-table-column',{attrs:{"align":"left","prop":"serial","label":"序号","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("serial")(scope.row.uuId,scope.row.dataType, _vm.stageData[`${item.deptClassifyId}`]))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"任务名称","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.dataType == 'stage')?_c('el-input',{attrs:{"disabled":scope.row.status == 20 ||
                _vm.options.deptClassifyId !== item.deptClassifyId ||
                _vm.disabled,"placeholder":"请输入"},model:{value:(scope.row.stageName),callback:function ($$v) {_vm.$set(scope.row, "stageName", $$v)},expression:"scope.row.stageName"}}):_vm._e(),(scope.row.dataType == 'task')?_c('el-input',{attrs:{"disabled":scope.row.status == 20 ||
                _vm.options.deptClassifyId !== item.deptClassifyId ||
                _vm.disabled,"placeholder":"请输入"},model:{value:(scope.row.taskName),callback:function ($$v) {_vm.$set(scope.row, "taskName", $$v)},expression:"scope.row.taskName"}}):_vm._e()]}}],null,true)}),_c('el-table-column',{attrs:{"label":"计划开始时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.dataType == 'stage')?_c('el-date-picker',{ref:"stageSdate",refInFor:true,attrs:{"disabled":scope.row.status == 20 ||
                _vm.options.deptClassifyId !== item.deptClassifyId ||
                _vm.disabled,"value-format":"timestamp","type":"date","placeholder":"请选择"},on:{"change":_vm.stageStartTimeFn},model:{value:(scope.row.planStartDate),callback:function ($$v) {_vm.$set(scope.row, "planStartDate", $$v)},expression:"scope.row.planStartDate"}}):_vm._e(),(scope.row.dataType == 'task')?_c('el-date-picker',{attrs:{"disabled":scope.row.status == 20 ||
                _vm.options.deptClassifyId !== item.deptClassifyId ||
                _vm.disabled,"value-format":"timestamp","type":"date","picker-options":_vm.pickerOptionsTask0,"placeholder":"请选择"},on:{"change":_vm.taskStartTimeFn,"focus":function($event){return _vm.taskStartFn(scope.row)}},model:{value:(scope.row.taskStartDate),callback:function ($$v) {_vm.$set(scope.row, "taskStartDate", $$v)},expression:"scope.row.taskStartDate"}}):_vm._e()]}}],null,true)}),_c('el-table-column',{attrs:{"label":"计划结束时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.dataType == 'stage')?_c('el-date-picker',{ref:"stageEdate",refInFor:true,attrs:{"disabled":scope.row.status == 20 ||
                _vm.options.deptClassifyId !== item.deptClassifyId ||
                _vm.disabled,"value-format":"timestamp","type":"date","placeholder":"请选择","picker-options":_vm.pickerOptionsStage},on:{"change":_vm.stageEndTimeFn},model:{value:(scope.row.planEndDate),callback:function ($$v) {_vm.$set(scope.row, "planEndDate", $$v)},expression:"scope.row.planEndDate"}}):_vm._e(),(scope.row.dataType == 'task')?_c('el-date-picker',{attrs:{"disabled":scope.row.status == 20 ||
                _vm.options.deptClassifyId !== item.deptClassifyId ||
                _vm.disabled,"value-format":"timestamp","type":"date","placeholder":"请选择","picker-options":_vm.pickerOptionsTask1},on:{"focus":function($event){return _vm.taskEndFn(scope.row)}},model:{value:(scope.row.taskEndDate),callback:function ($$v) {_vm.$set(scope.row, "taskEndDate", $$v)},expression:"scope.row.taskEndDate"}}):_vm._e()]}}],null,true)}),_c('el-table-column',{attrs:{"label":"实际完成时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-date-picker',{attrs:{"disabled":scope.row.status == 20 ||
                _vm.options.deptClassifyId !== item.deptClassifyId ||
                _vm.disabled,"value-format":"timestamp","type":"date","placeholder":"请选择"},on:{"change":_vm.completeTimeFn},model:{value:(scope.row.accomplishDate),callback:function ($$v) {_vm.$set(scope.row, "accomplishDate", $$v)},expression:"scope.row.accomplishDate"}}),(scope.$index == _vm.completeIndex)?[(_vm.completeTime)?_c('div',{staticStyle:{"color":"red","font-size":"12px"}},[_vm._v(" * 请选择完成时间段 ")]):_vm._e()]:_vm._e()]}}],null,true)}),_c('el-table-column',{attrs:{"label":"任务内容","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{attrs:{"disabled":scope.row.status == 20 ||
                _vm.options.deptClassifyId !== item.deptClassifyId ||
                _vm.disabled,"placeholder":"请输入"},model:{value:(scope.row.taskContent),callback:function ($$v) {_vm.$set(scope.row, "taskContent", $$v)},expression:"scope.row.taskContent"}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"任务状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("dict")(scope.row.status,_vm.dictData.projectStagePlanStatus))+" ")]}}],null,true)}),(_vm.options.isViewMonitoring)?[_c('el-table-column',{attrs:{"label":"关联合同阶段","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.dataType == 'stage')?_c('el-select',{attrs:{"disabled":scope.row.status == 20 ||
                  _vm.options.deptClassifyId !== item.deptClassifyId ||
                  _vm.disabled,"placeholder":"请选择"},model:{value:(scope.row.agreementStagePlanId),callback:function ($$v) {_vm.$set(scope.row, "agreementStagePlanId", $$v)},expression:"scope.row.agreementStagePlanId"}},_vm._l((_vm.originalForm.agreementStageList),function(n){return _c('el-option',{key:n.id,attrs:{"label":n.stageName,"value":n.id}})}),1):_vm._e()]}}],null,true)})]:_vm._e(),(!(_vm.disabled || _vm.options.deptClassifyId !== item.deptClassifyId))?_c('el-table-column',{attrs:{"align":"center","width":"130"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [(_vm.projectInformation.status != 60)?_c('i',{staticClass:"iconfont iconadd-circle",on:{"click":function($event){return _vm.addStage(`${item.deptClassifyId}`, item.planType)}}}):_vm._e(),(_vm.options.status == item.status)?_c('i',{staticClass:"iconfont iconsuccess",on:{"click":function($event){return _vm.stagePlanAccomplish(item)}}}):_vm._e()]}},{key:"default",fn:function(scope){return [(scope.row.status == 10)?[(scope.row.dataType == 'stage' && scope.row.status == 10)?_c('i',{staticClass:"iconfont iconadd-circle",on:{"click":function($event){return _vm.addTask(`${item.deptClassifyId}`, item.planType, scope)}}}):_vm._e(),(scope.row.status == 10)?_c('i',{staticClass:"iconfont iconerror",on:{"click":function($event){return _vm.del(`${item.deptClassifyId}`, scope)}}}):_vm._e(),(scope.row.status == 10 && scope.row.id)?_c('i',{staticClass:"iconfont iconsuccess",on:{"click":function($event){return _vm.complete(scope)}}}):_vm._e()]:_c('el-button',{attrs:{"type":"primary","plain":"","size":"small"},on:{"click":function($event){return _vm.complete(scope, 1)}}},[_vm._v("撤回")])]}}],null,true)}):_vm._e()],2)],1)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }